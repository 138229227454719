import React, { useState } from "react";

import { db } from "./../firebase";
import firebase from "firebase";
import Header from "./../components/header/Header";
import Footer from "../components/Footer";

const ContactPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [purpose, setPurpose] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");

  const handledbSubmit = (e) => {
    e.preventDefault();
    db.collection("contacts")
      .doc(email)
      .set(
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          purpose: purpose,
          additionalDetails: additionalDetails,
          edited: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      .then(() => {
        alert("Details have been saved");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setPurpose("");
        setAdditionalDetails("");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="ContactMain">
      <Header active="3" />
      <div className="ContactMainCon">
        <div className="ContactMainConL">
          {/* <img src={conBG} alt="" className="contactBG" /> */}
          <div className="formContainer" onSubmit={handledbSubmit}>
            <form className="form">
              <div className="line">
                <div className="ls">
                  <label>First Name *</label>
                  <input
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>

                <div className="ls lnLable">
                  <label className="">Last Name *</label>
                  <input
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="line">
                <div className="ls">
                  <label>Email *</label>
                  <input
                    required
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="line">
                <div className="ls">
                  <label>Purpose *</label>
                  <textarea
                    required
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                </div>
              </div>
              <div className="line">
                <div className="ls">
                  <label>Mobile number *</label>
                  <input
                    required
                    type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="line">
                <div className="ls">
                  <label>Additional Details</label>
                  <textarea
                    value={additionalDetails}
                    onChange={(e) => setAdditionalDetails(e.target.value)}
                  />
                </div>
              </div>
              <div className="btnLine">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div className="ContactMainConR">
          <div className="CMCRwrapper">
            <div className="lineR topL">
              <h1>
                <span>Get Connected</span> With Us.
              </h1>
            </div>
            <div className="lineR mid">
              <span className="line1">Office Address</span>
              <span>AUXILIP</span>
              <span className="line2">
                AIC-NIT Building, 1st Floor, Complex 1, Buddhist Villa, Chandaka
                Road
              </span>
              <span className="line3">Bhubaneswar - 754005, Odisha</span>
              <span className="line4">
                Mobile: +91-7540929509 / +91-7008301429
              </span>
              <span className="line5">Email Id: info@auxilip.com</span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactPage;
