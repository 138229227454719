// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import "./App.scss";
import LandingPage from "./pages/LandingPage";
import ContactPage from "./pages/ContactPage";
import About from "./pages/About";
import "./pages/AllStyles.scss";
import Header from "./components/header/Header";
import "./components/header/Header.scss";
import React, { useState } from "react";
import MainRouter from "./components/MainRouter";
import bg from "./assets/cycle.jpg";
function App() {
  const [posi, setPosi] = useState(1);
  return (
    <div className="App">
      <img src={bg} className="bgimg" />
      {/* <div className="headerMain" >
        <div className="hL">
          <img className="logoI" src={logoW} alt="" />
        </div>
        <div className="hM">
          <ul>
            <li className={(posi == 1 && ' active ')} onClick={()=>{setPosi(1)}}>
              <a  >Home</a>
            </li>
            <div className="circleDiv"></div>
            <li className={(posi == 2 && ' active ')} onClick={()=>{setPosi(2)}}>
              <a>About Us</a>
            </li>
            <div className="circleDiv"></div>
            <li className={(posi == 3 && ' active ')} onClick={()=>{setPosi(3)}}>
              <a>Contact</a>
            </li>
          </ul>
        </div>
        <div className="hR"></div>
      </div>
      <div
        className={
          'Slider ' +
          (posi == 1 && ' one ') +
          (posi == 2 && ' two ') +
          (posi == 3 && ' three ')
        }
      >
        <LandingPage />
        <About  />
        <ContactPage />
      </div> */}
      <MainRouter />
      {/* <Router>
        <Switch>
          <Route exact path="/"><LandingPage/></Route>
          <Route exact path="/Contact"><ContactPage/></Route>
          <Route exact path="/About"><About/></Route>
        </Switch>
      </Router> */}
    </div>
  );
}

export default App;
