import firebase from "firebase"
import "firebase/firestore"


var app = firebase.initializeApp({
    apiKey: "AIzaSyBhHoG1RpVsxKGVNFtTZDcU-uvnfSpnhDc",
    authDomain: "tronix-9eb0a.firebaseapp.com",
    projectId: "tronix-9eb0a",
    storageBucket: "tronix-9eb0a.appspot.com",
    messagingSenderId: "377251428246",
    appId: "1:377251428246:web:5d871e5988feb9dcdd4fb7",
    measurementId: "G-YDH5MCB9N3"
  })

  var db = app.firestore()
  export default app
  export { db}