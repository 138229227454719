import React, { useEffect, useState, useRef } from "react";
import Header from "./../components/header/Header";
// import bg1 from "./../assets/blob1.svg";
// import bg2 from "./../assets/lp/bg2.png";
import Footer from "../components/Footer";
import LOGO from "../assets/LOGO.png";

const socialLinks = {
  YouTube: "https://www.youtube.com/channel/UCI-9WMSELDGYzSktG3QGAeA",
  Instagram: "https://www.instagram.com/auxilipofficial/",
  LinkedIn: "https://www.linkedin.com/company/81324182/admin/",
  Facebook: "https://www.facebook.com/auxilipofficial/",
  Twitter: "https://twitter.com/auxilipofficial",
  mailId: "info@auxilip.com",
};

const LandingPage = () => {
  // const [timerDays, setTimerDays] = useState("00");
  // const [timerHours, setTimerHours] = useState("00");
  // const [timerMinutes, setTimerMinutes] = useState("00");
  // const [timerSeconds, setTimerSeconds] = useState("00");

  // let interval = useRef();

  // const startTimer = () => {
  //   const countdownDate = new Date("August 31,2022 12:00:00").getTime(); //ENTER YOUR COUNTDOWN DATE HERE
  //   interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = countdownDate - now;

  //     let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     let hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     let mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     let sec = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (days < 10) days = "0" + days;
  //     if (hours < 10) hours = "0" + hours;
  //     if (mins < 10) mins = "0" + mins;
  //     if (sec < 10) sec = "0" + sec;

  //     if (distance < 0) {
  //       clearInterval(interval.current);
  //     } else {
  //       setTimerDays(days);
  //       setTimerHours(hours);
  //       setTimerMinutes(mins);
  //       setTimerSeconds(sec);
  //     }
  //   }, 1000);
  // };

  // useEffect(() => {
  //   startTimer();
  //   return () => {
  //     clearInterval(interval.current);
  //   };
  // }, []);

  return (
    <div className="LandingPageMain">
      <Header active="1" />

      <div className="socialLinks ">
        <ul>
          <li>
            <a
              href={socialLinks.Instagram}
              target="_blank"
              class="fab fa-instagram"
            ></a>
          </li>
          <li>
            <a
              href={socialLinks.Twitter}
              target="_blank"
              class="fab fa-twitter"
            ></a>
          </li>
          <li>
            <a
              href={socialLinks.LinkedIn}
              target="_blank"
              class="fab fa-linkedin-in"
            ></a>
          </li>
          <li>
            <a
              href={socialLinks.Facebook}
              target="_blank"
              class="fab fa-facebook"
            ></a>
          </li>
          <li>
            <a
              href={socialLinks.YouTube}
              target="_blank"
              class="fab fa-youtube"
            ></a>
          </li>
          <li></li>
        </ul>
      </div>
      <div className="LandingPageCon">
        <img src={LOGO} className="lpCenterLogo" alt="Auxilip" />
        {/* <div className="CounterContainer">
          <div className="ccR">
            <div className="dayS timerS">
              <h1>{timerDays}</h1>
              <h2>Days</h2>
            </div>
            <div className="dDots">
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
            <div className="HourS timerS">
              <h1>{timerHours}</h1>
              <h2>Hour</h2>
            </div>
            <div className="dDots">
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
            <div className="MinS timerS">
              <h1>{timerMinutes}</h1>
              <h2>Minutes</h2>
            </div>
            <div className="dDots">
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
            <div className="SecS timerS">
              <h1>{timerSeconds}</h1>
              <h2>Seconds</h2>
            </div>
          </div>
        </div> */}
        <div className="LandingPageConBottomM">
          <div className="BottomTop">
            <h2>
              Great minds of{" "}
              <span className="primaryColor lpBsize">Auxilip</span> busy
              building awesome products for you.
            </h2>
            <h4>Stay in touch.</h4>
          </div>

          <Footer classname="BottomBtm" />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
