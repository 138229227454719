import React from "react";
import "./TeamSection.scss";
import souravPic from "../assets/souravPic.jpeg";
import debPic from "../assets/deb1.jpg";

const TeamSection = () => {
  return (
    <>
      <div class="TeamMain">
        <div className="heading">
          <div className="text1">Our Leaders</div>
        </div>
        <div class="cardBlock">
          <Card
            name="Debabrata Paikaray"
            designation="Founder & CEO"
            image={debPic}
            linkedin="https://www.linkedin.com/in/debabrata-paikaray-design-engineer/"
            twitter="https://twitter.com/dpaikaray19"
            instagram="https://www.instagram.com/__its.deb__/"
            facebook="https://www.facebook.com/debabrata.paikaray"
          />

          <Card
            name="Sourav Kumar Mishra"
            designation="Director"
            image={souravPic}
            linkedin="https://www.linkedin.com/in/sourav-mishra-9a46a8103"
            twitter="https://mobile.twitter.com/sourav960"
          />
        </div>
      </div>
    </>
  );
};

export default TeamSection;

const Card = ({
  name,
  image,
  designation,
  linkedin,
  twitter,
  instagram,
  facebook,
}) => (
  <div class="col-md-6 col-lg-3 card">
    <div class="img-block mb-5">
      <img src={image} alt="image1" />
      <div class="content ">
        <h4>{name}</h4>
        <p class="text-muted">{designation}</p>
        <div class="social-links">
          {linkedin && (
            <a href={linkedin} target="_blank" class="fab fa-linkedin-in"></a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" class="fab fa-twitter"></a>
          )}
          {facebook && (
            <a href={facebook} target="_blank" class="fab fa-facebook"></a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" class="fab fa-instagram"></a>
          )}
        </div>
      </div>
    </div>
  </div>
);
