import React from "react";

const Footer = ({ classname }) => {
  let thisYear = new Date().getFullYear();
  return (
    <div className={`${classname ? classname : "copywrite"}`}>
      <h4>
        All information is subject to specific conditions | Copyright ©{" "}
        {thisYear} Auxilip All rights reserved.
      </h4>
    </div>
  );
};

export default Footer;
