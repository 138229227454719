import React from "react";
import Header from "./../components/header/Header";
import Footer from "../components/Footer";
import TeamSection from "./TeamSection";

const About = () => {
  return (
    <div className="AboutMain">
      <Header active="2" />
      {/* <img src={bg1} alt="" className="bgO" /> */}
      <div className="AboutMainCon">
        <div className="AboutT">
          {/*
          <div className="AboutTL">
             <h2>
              At Imaginxt, We build quality products, that redirect the future
              with commitment of socialism
            </h2> 
          </div>
            */}
          <div className="AboutTR">
            <h2>About Us</h2>
          </div>
        </div>
        <div className="AboutB">
          {/* <div className="AboutBL">
            <img src={aboutI} alt="aboutI" />
          </div> */}
          <div className="AboutBR">
            <h4>
              <span className="primaryColor">Auxilip </span>
              is an India- based startup, focusing on designing, engineering and
              manufacturing EV conversion kit and in - house built EV components
              for global.
            </h4>
            <h4>
              The company has the vision to change the way people move and
              aiming to be an affordable and accessible electric form of
              mobility for everyone, to de - carbonize environment, to
              transforming lives and to drive business efficiency.
            </h4>
            <h4>
              The scale of challenges are enormous, but we are lucky to be a
              part of this to be able to help/solve how we shift our planet’s
              energy and transportation systems entirely away from fossil fuel
              and we have made it our mission to take it there.
            </h4>
          </div>
        </div>
        <TeamSection />

        <Footer />
      </div>
    </div>
  );
};

export default About;
