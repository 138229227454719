import React, { useState } from "react";
import "./Header.scss";
import logoW from "./../../assets/LOGO.png";
import hamburger from "./../../assets/ham.png";

import { Link } from "react-router-dom";
const Header = (props) => {
  const [hamState, setHamState] = useState(false);
  return (
    <div className="headerM">
      <div className="headerMain">
        <div className="hL">
          <img
            className={`logoI ${hamState && "hideLogo"} `}
            src={logoW}
            alt="auxilip"
          />
        </div>
        <div className="hM">
          <ul>
            <li className={props.active === "1" && " active "}>
              <Link to="/">Home</Link>
            </li>
            <div className="circleDiv"></div>
            <li className={props.active === "2" && " active "}>
              <Link to="/About">About Us</Link>
            </li>
            <div className="circleDiv"></div>
            <li className={props.active === "3" && " active "}>
              <Link to="/Contact">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="hMOB">
          <img onClick={() => setHamState(!hamState)} src={hamburger} alt="" />
          {/* <span
            class="fa-solid fa-bars"
            onClick={() => setHamState(!hamState)}
          ></span> */}
        </div>
      </div>
      <div className={"hMOB-menu " + (hamState && " active ")}>
        <ul>
          <li className={props.active === "1" && " activee "}>
            <Link to="/">Home</Link>
          </li>
          <div className="circleDiv"></div>
          <li className={props.active === "2" && " activee "}>
            <Link to="/About">About Us</Link>
          </li>
          <div className="circleDiv"></div>
          <li className={props.active === "3" && " activee "}>
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
