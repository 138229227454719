import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import About from './../pages/About'
import LandingPage from './../pages/LandingPage'
import ContactPage from './../pages/ContactPage'
import './../pages/ResponsiveStyles.scss'

const MainRouter = () => {
  return (
    <div className="mrouts">
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/Contact">
            <ContactPage />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default MainRouter
